import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';


import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(injector: Injector, private http: HttpClient, private _authService: AppAuthService) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(
            (error: any, caught: Observable<HttpEvent<any>>) => {
                if (error.status === 401) {
                    this._authService.logout(true);
                    localStorage.setItem("showLogoutMsg", 'true');
                    return of(error);
                }
                else if (error.status === 0) {
                    return of(error);
                }
                throw error;
            }
        ));
    }
}