import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css'],
  animations: [accountModuleAnimation()]
})
export class MaintenanceComponent implements OnInit {

  constructor(private router: Router) {
    this.loadScripts();
  }

  ngOnInit() {
    this.checkApiStatus();
    setInterval(() => {
      this.checkApiStatus();
    }, 60000); // Check every 60 seconds
  }

  loadScripts() {
    const dynamicCss = [
      'assets/anonymouspage/css/style.css',
    ];
    for (let i = 0; i < dynamicCss.length; i++) {
      const node = document.createElement('link');
      node.href = dynamicCss[i];
      node.rel = 'stylesheet';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  checkApiStatus() {
    const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
    const token = abp.auth.getToken();

    let requestHeaders = {
      '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue),
      'Abp.TenantId': abp.multiTenancy.getTenantIdCookie()
    };

    if (token) {
      requestHeaders['Authorization'] = 'Bearer ' + token;
    }

    return abp.ajax({
      url: AppConsts.remoteServiceBaseUrl + '/AbpUserConfiguration/GetAll',
      method: 'GET',
      headers: requestHeaders,
      abpHandleError: false
    }).done(result => {
      this.router.navigateByUrl("/");
    });
  }
}
